<template>
  <div class="user-select" no-body :title="standardsName">
     <div class="row">
      <div class="col-12 mb-1">
        <select name="" id="" class="form-control float-right" style="width:200px;" placeholder="filter" @change="subsetFilter()" v-model="subsetId">
          <option value=""> Select Subset</option>
          <option :value="sub._id" v-for="sub in subsetlist" :key="sub._id">{{sub.name}}</option>
        </select>
      </div>
    </div>
     <div class="row p-1 mb-1 card" >
      <div class="d-flex align-items-center p-1" style="width:100%;">
      <div class="col-10">
        <h5>
          {{ standardsNameVal }}
        </h5>
      </div>
      <div class="col-1">
        <div class="d-flex align-items-center">
        

        <b-badge variant="primary" v-if="isPrimaryVal" class="mr-1">
          Primary
        </b-badge>

        <b-badge variant="success" v-if="isPublic" >
          Published
        </b-badge>
      </div>
      </div>
      </div>
    </div>
  
    
      <div class="row top-data">
        <button
          class="btn btn-primary mr-bottom margin-right"
          v-if="standarddetails.republish == '1'"
          @click="openPublicModal()"
        >
          Republish
        </button>
        <button
          @click="ImportControlsmodel()"
          class="btn pointer btn-success btn-md mr-bottom margin-right"
         
        >
         <!-- v-if="!isPublic" -->
          <feather-icon icon="FilePlusIcon" size="15"></feather-icon>
          Import Controls
        </button>

        <button
          @click="openPrimaryModal()"
          class="btn mr-bottom pointer btn-success btn-md mr-1"
          v-if="!isPrimaryVal"
        >
          <feather-icon
            v-if="isPrimaryVal"
            class="rotate"
            icon="CheckCircleIcon"
            size="15"
          />
          <span v-if="isPrimaryVal"> Primary </span>
          <span v-else> Set as Primary </span>
        </button>
        <!-- <b-form-checkbox
          :checked="false"
          name="check-button"
          button
          inline
          size="md"
          v-model="isPrimary"
          button-variant="success"
          @click="setAsPrimary()"
        >
          <span>Set as Primary</span>
         
        </b-form-checkbox> -->
        <!-- <button  @click="setAsPrimary()"></button> -->
        <button
          @click="openPublicModal()"
          class="btn mr-bottom pointer btn-primary btn-md"
          v-if="!isPublic"
        >
          <span v-if="isPublic"> Published </span>
          <span v-else> Publish </span>
        </button>
      </div>
    
    <!-- <b-card> -->
    <div>
      <Tree
        type="Tree"
        @treeData="recursion"
        @edit="editDettails"
        @saveData="insertDataValnew"
        :node="allStandards"
      ></Tree>
    </div>
    <div>
      <Tree
        @treeData="recursion"
        @edit="editDettails"
        @saveData="insertDataValnew"
        :node="allStandards"
      ></Tree>
    </div>

    <!-- </b-card> -->
    <b-card class="margin">
      <span @click="addFamilyFirst()">
        <feather-icon
          class="text-primary pointer rotate"
          icon="PlusCircleIcon"
          size="15"
        />
        Add Control Family
      </span>
    </b-card>

    <b-sidebar id="sidebar-right" bg-variant="white" right backdrop shadow>
      <editstandards
        :standards="allStandards"
        :editdata="controlFmailyId"
        :closeSidebar="closeSidebar"
        :reloadMasterComponent="load"
        :responseset="responseSet"

      />
    </b-sidebar>
    <span class="mr-btm-5" v-if="showFamilyData">
      <div class="margin">
        <div class="d-flex align-items-center">
          <input
            v-if="showFmailyInput"
            placeholder="Control family no"
            v-model="controlNumber"
            class="form-control no-Padding margin-right input-sm flex"
          />
          <input
            v-if="showFmailyInput"
            v-model="controlFamilyname"
            placeholder="Control family name"
            class="form-control input-sm margin-right flex"
          />
          <!--Subset-->
          <div style="min-width: 200px; margin-right: 1rem">
            <!-- <v-select
              v-if="showFmailyInput"
              v-model="subset"
              :reduce="(option) => option.value"
              :options="label_options"
              label="text"
              multiple
              placeholder="Select Subset"
            /> -->
             <treeselect v-model="subset" :multiple="true" :options="label_options"  v-if="showFmailyInput" style="max-width:200px;" />
          </div>
          <!--Subset-->
          <b-form-textarea
            v-if="showFmailyInput"
            rows="1"
            placeholder="description"
            v-model="controlFamilyDescription"
            class="jjj no-Padding"
          />
          <div class="flex">
            <button v-if="showFmailyInput" class="btn btn-sm" @click="closeaddControlFamily">cancel</button>
            <button
              v-if="showFmailyInput"
              class="btn btn-primary btn-sm"
              @click="addControlFamily(standardId)"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </span>
    <b-modal
      ref="modal-primary"
      title="Are you sure to set this standard as primary? "
      hide-footer
    >
      <button class="btn btn-success" @click="setAsPrimary()">
        Make it primary
      </button>

      <!-- {{label_options}} -->
    </b-modal>
    <b-modal
      ref="modal-public"
      title="Are you sure to publish this standard? "
      hide-footer
    >
      <p>Current Version : {{ standarddetails.version }}</p>
      <input
        type="text"
        class="form-control mb-1 mt-1"
        placeholder="add new version number"
        v-model="version_no"
      />
      <b-form-checkbox
        id="checkbox-1"
        v-model="forceupdate"
        name="checkbox-1"
        value="1"
        unchecked-value="0"
        v-b-tooltip.hover.left.v-danger
        title="By enabling force update, standard will be updated in 'CISO-GRC' and all ongoing Gap Assessments will be updated without any notice."
      >
        Force Update
      </b-form-checkbox>
      <button class="btn btn-primary mt-1" @click="makePublic()">
        Make it public
      </button>

      <!-- {{label_options}} -->
    </b-modal>
    <b-modal ref="modal-import" title="Import Controls " hide-footer>
      <b-form-file
        v-model="uploadfile"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        class="mb-1"
      />
      <!-- {{ JSON.stringify(this.file, name) }} -->
      <button class="btn btn-success btn-sm" @click="UploadControl()">
        Upload
      </button>

      <!-- {{label_options}} -->
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BSidebar,
  VBToggle,
  BFormTextarea,
  BFormCheckbox,
  BModal,
  VBModal,
  BBadge,
  BFormFile,
  VBTooltip,
} from "bootstrap-vue";
import Vue from "vue";
import Tree from "./TreeDraft.vue";
import editstandards from "../editstandards.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
 import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  data: () => ({
    allStandards: [],
    allControlsFamily: [],
    allControls: [],
    allControlsGroup: [],
    filteredFamily: [],
    controlNumber: "",
    controlFamilyname: "",
    controlFamilyDescription: "",
    familyGroupName: "",
    familyGroupDescription: "",
    standardId: "",
    controlFmailyId: {},
    hideSlidebar: true,
    hideInputVal: true,
    addFamily: false,
    hideFamily: false,
    showFamilyData: false,
    showFmailyInput: false,
    standardsName: "",
    standardsNameVal: "",
    isPrimaryVal: false,
    tableFamily: {},
    isPrimary: false,
    isPublic: false,
    uploadfile: "",
    standarddetails: [],
    version_no: "",
    forceupdate: 0,
    subsetlist: [],
    subset: [],
    label_options: [],
    subsetId:'',
    responseSet:[],
  }),
  components: {
    BCard,
    BCardText,
    BLink,
    Tree,
    BButton,
    BSidebar,
    editstandards,
    BCardCode,
    BFormTextarea,
    BFormCheckbox,
    BModal,
    VBModal,
    BBadge,
    BFormFile,
    vSelect,
    Treeselect,
    VBTooltip,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    "b-tooltip":VBTooltip,
  },

  watch: {
    $route(to, from) {
      this.standardId = this.$route.params.id;
      this.standardsName = this.$route.meta.pageTitle;
      //   this.getStandardsFamily(this.standardId);
    },
  },

  mounted() {
    this.load();
  },
  methods: {
     closeaddControlFamily(){
     this.showFamilyData = false;
    },
      subsetFilter(){
       const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${this.standardId}/draft?subset_id=${this.subsetId}`,
      };
      this.$http(options)
        .then((res) => {
          this.standardsNameVal = res.data.data.standard.name;
          this.isPrimaryVal =
            res.data.data.standard.is_primary == "1" ? true : false;
          this.isPublic =
            res.data.data.standard.is_published == "1" ? true : false;
          this.standarddetails = res.data.data.standard;
          this.allStandards = res.data.data.families;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSubset(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetlist = res.data.data;
          this.label_options = [];
          for (let [key, value] of Object.entries(this.subsetlist)) {
            let a = {
              id: value._id,
              label: value.name,
            };
            // console.log(res.data[key].profile.organization)
            this.label_options.push(a);
          }
        })
        .catch((err) => {});
    },
    gotodraft() {
      this.$router.push({
        name: "Standarddraft",
        params: { id: this.standardId },
      });
    },
    ImportControlsmodel() {
      this.$refs["modal-import"].show();
    },
    openPrimaryModal() {
      this.$refs["modal-primary"].show();
    },
    openPublicModal() {
      this.$refs["modal-public"].show();
    },
    load() {
      this.bindEvents();
      this.standardId = this.$route.params.id;
      this.getStandard(this.standardId);
      this.getSubset(this.standardId);
      //   this.getStandardsFamily(this.standardId);
    },
    UploadControl() {
      const formData = new FormData();
      formData.append("controls_list", this.uploadfile);
      formData.append("standard_id", this.standardId);
      // const data = {
      //   standard_id: this.standardId,
      //   controls_list: this.uploadfile
      // };
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/fileupload/standard`,
      };
      this.$http(options)
        .then((res) => {
          this.getStandard(this.standardId);
          this.$router.go();
          this.uploadfile = null;
          this.$refs["modal-import"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makePublic() {
      const data = {
        version: this.version_no,
        force_update: this.forceupdate,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.standardId}/publish`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;

          this.getStandard(this.standardId);
          this.version_no = "";
          this.$router.go(-1);
          this.$refs["modal-public"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setAsPrimary() {
      // alert("hello")
      // if (!this.isPrimary) {
      //   this.isPrimary = true;
      // } else {
      //   this.isPrimary = false;
      // }
      // const data = {
      //   is_primary: this.isPrimary ? 1 : 0,
      // };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/${this.standardId}/primary`,
      };
      this.$http(options)
        .then((res) => {
          // this.getStandards();
          // this.sliderVal = false;
          this.getStandard(this.standardId);
          this.$refs["modal-primary"].hide();
          this.$router.go(-1);

          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data);
        })
        .catch((err) => {
           this.$toast({
              component: ToastificationContent,
              props: {
                title: err.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          console.log(err);
        });
    },

    listViewRouter() {
      this.$router.push({ name: "Treelist", id: this.standardId });
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    getStandard(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${id}/draft`,
      };
      this.$http(options)
        .then((res) => {
          this.standardsNameVal = res.data.data.standard.name;
          
          this.isPrimaryVal =
            res.data.data.standard.is_primary == "1" ? true : false;
          this.isPublic =
            res.data.data.standard.is_published == "1" ? true : false;
          this.standarddetails = res.data.data.standard;
          this.responseSet = this.standarddetails.response_set;
          this.allStandards = res.data.data.families;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFamilyFirst() {
      const stId = this.$route.params.id;
      if (stId) {
        this.showFmailyInput = !this.showFmailyInput;
        this.showFamilyData = true;
      }
    },
    bindEvents() {
      this.$root.$off("delete").$on("delete", (data) => {
        // alert(JSON.stringify(data));
        if (!data.control_family_id) {
          this.deteteFamily(data);
        } else if (data.type == "Control" || data.type == "Group") {
          this.deteteControl(data, data.index);
        } else {
          this.deleteSubControl(data, data.index);
        }
      });
      this.$root.$on("tableFamily", (data) => {
        this.addControlFamily(this.standardId, "tableFamilydata", data);
      });
      this.$root.$off("addControl").$on("addControl", (data) => {
        this.insertControl(data);
      });
      this.$root.$off("addSubControl").$on("addSubControl", (data) => {
        this.addSubControl(data);
      });
    },

    addSubControl(data) {
      // alert(data)
      console.log(data);
      let self = this;
      const body = {
        control_no: data.control_no,
        name: data.name,
        description: data.description,
        standard_id: data.standard_id,
        type: data.type,
        control_family_id: data.control_family_id,
        parent_id: data.draft_status == 1 ? data.draft_parent : data.parent_id,
        subset_id: data.subset_id,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong try again",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subcontrol created !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          const splitIndex = data.indexValue.split("_");
          let result = {};
          for (let i = 0; i < splitIndex.length; i++) {
            if (i == 0) {
              result = self.allStandards[splitIndex[i]];
            } else {
              result = result.child[splitIndex[i]];
            }
          }
          Vue.set(result, "subcontrols", res.data.data.subcontrols);
          Vue.set(result, "child", res.data.data.subcontrols);
          // this.$router.go()
          this.$root.$emit("hideinput", { type: "subcontrol", id: data.id });
          this.getStandard(this.standardId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideInput(id, data) {
      this.hideInputVal = false;
      let hidedata = {
        inputKeyVal: this.hideInputVal,
        inputId: id,
        insertData: data,
      };
      this.$root.$emit("hideinput", hidedata);
    },

    editDettails(editObj) {
      /////////////////////////////////(99999//////////////////////////////
      // alert(JSON.stringify(editObj))
      this.openSidebar();
      this.controlFmailyId = editObj;
      this.$root.$emit("editValdata", editObj);
    },

    getControl(id, index) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls/${id}`,
      };
      this.$http(options)
        .then((res) => {
          const coppyData = this.allStandards;
          const val = coppyData.filter(
            (ele) => ele._id == res.data.data.control.control_family_id
          );
          const childData = val[0].child.filter(
            (ele) => ele.name == res.data.data.control.name
          );

          Vue.set(childData[0], "child", res.data.data.subcontrols);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deteteFamily(data, index) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/cl_families/${data._id}/depricate`,
      };
      this.$http(options)
        .then((res) => {
          this.getStandardsFamily(data.standard_id);

          this.allStandards = res.data.data;
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control Family Depricated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // setTimeout(this.showTeamList, 5000);
    },
    deteteControl(data, index) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/controls/${data._id}/depricate`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control Depricated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          if (data.type == "Control") {
            this.getControl(data.parent_id, index);
          } else if (data.type == "Group") {
            this.getFamily(data.control_family_id, index);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteSubControl(data, index) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/controls/${data._id}/depricate`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong !",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Subcontrol Depricated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          if (data.type == "Control") {
            this.getControl(data.parent_id, index);
          } else if (data.type == "Group") {
            this.getFamily(data.control_family_id, index);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFamily(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families/${id}/draft`,
      };
      this.$http(options)
        .then((res) => {
          const coppyData = this.allStandards;
          const val = coppyData.filter(
            (ele) => ele._id == res.data.data.control._id
          );
          Vue.set(val[0], "child", res.data.data.subcontrols);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bindValue(val, index) {
      const splitIndex = index.split("_");
      let result = {};
      for (let i = 0; i < splitIndex.length; i++) {
        if (i == 0) {
          result = this.allStandards[splitIndex[i]];
        } else {
          result = result.child[splitIndex[i]];
        }
      }
      if (!Array.isArray(val)) {
        if (!result.child) {
          result.child = [];
        }
        let duplicate = [];
        duplicate = result.child;
        duplicate.push(val);
        val = duplicate;
      }
      Vue.set(result, "child", val);
    },

    recursion(data) {
      const index = data.e.target.dataset.index;
      let controlName;
      let block = false;
      let url = process.env.VUE_APP_ONECSF_API_URL;
      //   if (Object.keys(data.value).includes("subcontrols")) {
      //     data.value.subcontrols.forEach((ele, key) => {
      //       ele.type = "Subcontrol";
      //       ele.control_id = data.value._id;
      //       ele.index = key;
      //     });
      // data.value.childType = "Subcontrol";
      //     Vue.set(data.value, "child", data.value.subcontrols);
      //     block = true;
      //   } else
      if (
        Object.keys(data.value).includes("control_family_id") &&
        Object.keys(data.value).includes("standard_id")
      ) {
        url += `/controls`;
        if (data.value.type == "Control") {
          data.value.childType = "Sub control";
        } else {
          data.value.childType = "Control";
        }
      } else if (
        Object.keys(data.value).includes("standard_id") &&
        !Object.keys(data.value).includes("control_family_id")
      ) {
        url += `/cl_families`;
        if (data.value.type == null) {
          data.value.childType = "Controls / Group";
        }
      }
      if (!block) {
        const options = {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
          url: url + `/${data.value._id}/draft?subset_id=${this.subsetId}`,
        };
        this.$http(options)
          .then((res) => {
            if (
              res.data.data.type == null &&
              !res.data.data.subcontrols.length
            ) {
              data.value.childType = null;
            } else if (res.data.data.type == "Group") {
              data.value.childType = "Group";
            } else if (res.data.data.type == "Control") {
              data.value.childType = "Control";
            } else {
              data.value.childType = "Subcontrol";
            }
            this.bindValue(res.data.data.subcontrols, index);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    insertDataValnew(data) {
      this.insertControl(data);
    },
    getStandardsFamily(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/cl_families?standard_id=${id}`,
      };
      this.$http(options)
        .then((res) => {
          if (!res.data.data.data.length) {
            this.addFamily = true;
            let addObj = {
              id: id,
              showValue: this.addFamily,
            };
            this.$root.$emit("addFamilyaData", addObj);
          } else {
            this.allStandards = res.data.data.data.sort((a, b) =>
              a.control_no > b.control_no
                ? 1
                : b.control_no > a.control_no
                ? -1
                : 0
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertControl(control) {
      // control.insertData.selected = control.datavalue.type == 'Group' ? control.datavalue.childType : control.insertData.selected
      // if (control.insertData.selected != 'Control / Group') {
      // alert('hai');
      let dataParentId = "";
      let selectedType = "";
      if (!control.insertData.selected) {
        selectedType = control.datavalue.childType;
      }
      if (control.datavalue.childType != "Control") {
        dataParentId = "0";
      } else {
        if (control.datavalue.type == "Group") {
          dataParentId =
            control.datavalue.draft_status == 1
              ? control.datavalue.draft_parent
              : control.datavalue._id;
        } else {
          dataParentId = "0";
        }
      }
      const body = {
        name: control.insertData.dataName,
        description: control.insertData.dataDescription,
        standard_id: control.datavalue.standard_id,
        control_family_id:
          control.datavalue.childType == "Control" &&
          Object.keys(control.datavalue).includes("control_family_id")
            ? control.datavalue.control_family_id
            : control.datavalue.draft_status == 1
            ? control.datavalue.draft_parent
            : control.datavalue._id,
        control_no: control.insertData.dataControlNumber,
        parent_id: dataParentId,
        onecsf_control_id: "",
        type: control.insertData.selected
          ? control.insertData.selected
          : selectedType,
        subset_id: control.subset_id,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/controls`,
      };
      const coppyData = this.allStandards;
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong try again",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control created !",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          control.insertData.dataName = "";
          control.insertData.dataDescription = "";
          control.insertData.dataControlNumber = "";
          if (control.datavalue.childType == "Group") {
            coppyData[control.indexValue].child.push(res.data.data);
          } else {
            if (
              control.datavalue.childType == "Control" &&
              !Object.keys(control.datavalue).includes("control_family_id")
            ) {
              coppyData[control.indexValue].child.push(res.data.data);
            } else if (control.datavalue.childType == "Control / Group") {
              coppyData[control.indexValue].child.push(res.data.data);
            } else {
              this.bindValue(res.data.data, control.indexValue);
            }
          }
          if (res.data.data.type == "Group") {
            control.datavalue.childType = "Group";
          } else {
            control.datavalue.childType = "Control";
          }
          this.hideInput(control.datavalue._id);
          this.getStandard(this.standardId);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },
    addControlFamily(id, type, data) {
      let body = {};
      if (type == "tableFamilydata") {
        body = {
          name: data.data.dataName,
          description: data.data.dataDescription,
          standard_id: id,
          control_no: data.data.dataControlNumber,
          subset_id: this.subset,
        };
      } else {
        body = {
          name: this.controlFamilyname,
          description: this.controlFamilyDescription,
          standard_id: id,
          control_no: this.controlNumber,
          subset_id: this.subset,
        };
      }
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families`,
      };
      const coppyDataFM = this.allStandards;
      this.$http(options)
        .then((res) => {
          this.getStandardsFamily(id);
          (this.controlFamilyname = ""),
            (this.controlFamilyDescription = ""),
            (this.controlNumber = "");

          this.showFmailyInput = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.margin {
  margin-left: 35px;
}
.mr-bottom {
  margin-bottom: 10px;
}
.top-data {
  display: flex;
  justify-content: end;
  align-items: center;
}
.user-select {
  user-select: none;
}
.text-primary-green {
  margin-left: 10px;
}
</style>
